import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useBoards, useReviews, useOrganizations } from '../../../hooks';
import { serializeData } from '../../../utils/serialization';
import { generateQuery } from '../../../utils/reviews';
import '../../../style/page.scss';
import Page from '../../../components/page';
import ReviewTable from '../../../components/reviewTable';

const ReviewsBoardPage = ({ params }) => {
  const { isFetchingBoards, getBoards, getBoard } = useBoards();
  const { isFetchingOrgs, getOrganizations } = useOrganizations();
  const { isFetchingReviews, getBoardReviews } = useReviews();
  const [boards, setBoards] = useState([]);
  const [boardName, setBoardName] = useState('');
  const [boardImages, setBoardImages] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [count, setCount] = useState(0);
  const boardAbbr = decodeURIComponent(params.abbreviation);

  useEffect(() => {
    let mounted = true;
    (async () => {
      // fetch boards
      const res = await getBoards({
        getActiveReviewCounts: false,
        getImages: true,
        includePosition: true,
      });
      const boardImages = res.data.map(el => ({
        imageBase64: el.imageBase64,
        imageMime: el.imageMime,
        name: el.name,
      }));
      setBoardImages(boardImages);

      if (mounted && res && res.data) {
        const arr = [{ abbr: 'All', label: 'All' }];
        res.data.forEach(board => {
          arr.push({
            abbr: board.abbreviation.toLowerCase(),
            label: board.abbreviation,
          });
        });
        setBoards(arr);
      }
    })();

    (async () => {
      // fetch organizations
      const orgs = await getOrganizations();
      if (mounted && orgs && orgs.data) {
        setOrganizations(orgs.data);
      }
    })();

    // fetch reviews
    (async () => {
      // fetch reviews from the beginning
      await fetchReviews(
        decodeURIComponent(params.abbreviation),
        generateQuery(),
      );
    })();

    return () => (mounted = false);
  }, []);
  useEffect(() => {
    (async () => {
      const res = await getBoard(boardAbbr);
      if (res && res.data) setBoardName(res.data.name);
    })();
  }, [boardAbbr]);

  const fetchReviews = async (abbr: string, query: string) => {
    setReviews(null);
    const res = await getBoardReviews(abbr, query);
    if (res && res.data) {
      setCount(res.data.count);
      setReviews(await serializeData(res.data.reviews));
    }
  };

  return (
    <Page isFetching={isFetchingBoards || isFetchingOrgs}>
      <ReviewTable
        isFetchingReviews={isFetchingReviews}
        organizations={organizations}
        boards={boards}
        reviews={reviews}
        totalReviewsCount={count}
        boardAbbr={boardAbbr}
        boardName={boardName}
        boardImages={boardImages}
        onUpdate={async (abbr, query) => await fetchReviews(abbr, query)}
      />
    </Page>
  );
};

ReviewsBoardPage.propTypes = {
  params: PropTypes.any,
};

export default ReviewsBoardPage;
